import Head from "next/head";
import { FaGhost } from "react-icons/fa";
import styles from "../styles/Custom404.module.scss";
export default function Custom404() {
  return (
    <>
      <Head>
        <title>MagicalTrip: 404 Page Not Found</title>
      </Head>
      <main className={styles.Custom404}>
        <h1>
          4
          <span>
            <FaGhost />
          </span>
          4
        </h1>
        <h2>Error: 404 page not found</h2>
        <p>Sorry, the page you&apos;re looking for cannot be accessed</p>
      </main>
    </>
  );
}
